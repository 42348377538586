export const Team = (props) => {
  return (
    <div id='team' className='text-center' style={{ 'paddingTop': '0px' }}>
      <header id='header' className='foo'>
        <div className='intro' style={{
          'background': `url(${"../../img/team_banner.jpg"}) center center no-repeat`,
          'backgroundSize': 'cover',  // Changed from 'cover' to 'contain'
          // 'backgroundPositionY': '100%',  // This will show the top (try different percentages like 20%, 30% etc)
          'minHeight': '500px',  // Add this to ensure the container has enough height
        }}>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >

      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
        </div>
        <div id='row' className='text-left'>
          {props.data
            ? props.data.map((d, i) => (
              <div
                key={`${d.name}-${i}`}
                className='col-lg-4 team'
              >
                <div className='thumbnail'>
                  {' '}
                  <img src={d.img} alt='...' className='team-img' />
                  <div className='caption'>
                    <h4>{d.name}</h4>
                    <p>{d.job}</p>
                    <p>{d.description}</p>
                  </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div >
  )
}
