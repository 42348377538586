export const Header = (props) => {
  return (
    <header id='header' className='bar'>
      <div className='intro' style={{ 'background': `url(${"../../img/header.jpg"}) center center no-repeat`, 'backgroundSize': 'cover' }}>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <p style={{ fontSize: '3.0em' }}><strong>{props.data ? props.data.paragraph : 'Loading'}</strong></p>
                <a
                  href='#about'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Learn More
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
